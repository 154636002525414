html {
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #282C42;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1::selection, 
h2::selection, 
p::selection, 
a::selection, 
span::selection, 
strong::selection, 
del::selection, 
label::selection {
  color: #ffffff;
  background: #2D7DE6;
}

h1::-moz-selection, 
h2::-moz-selection, 
p::-moz-selection, 
a::-moz-selection, 
span::-moz-selection, 
strong::-moz-selection, 
del::-moz-selection, 
label::-moz-selection {
  color: #ffffff;
  background: #2D7DE6;
}
