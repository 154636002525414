html, body, #root {
  height: 100%;
}

.app {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #111111;
}

.header {
  height: 95px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 95px;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid #191919;
}

.header .service-name {
  line-height: 40px;
  color: #EFEFEF;
  font-size: 18px;
  padding-left: 12px;
  vertical-align: top;
}

.header > .spacer {
  flex: 1 1;
}

.header > .links {
  margin-left: 72px;
}

.header .gh-link {
  vertical-align: middle;
  margin-right: 0 !important;
}

.header > .links a {
  margin-right: 28px;
  font-size: 14px;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.content > .form-wrapper {
  max-width: 680px;
  margin: 0 auto;
  margin-top: 42px;
  margin-bottom: 80px;
}

.content > .info-page {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 42px;
  margin-bottom: 80px;
}

.content > .main-page {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 96px;
  margin-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
